import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import * as theme from '../theme'
import { TAlarmReportProps, TAgentReportProps } from '../Provider'
import { createDirectionalStyles } from '../Title/CommonUtils'

const commonStyles = StyleSheet.create({
  root: {
    color: theme.colors.documentSubHeader,
    padding: theme.padding(2),
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  fieldTitle: {
    ...theme.styles.subtitle,
    color: 'white',
  },
  fieldContent: {
    ...theme.styles.text,
    color: 'white',
  },
  alarmBackgroundColor: {
    backgroundColor: theme.colors.documentSubHeaderBg,
  },
  activityReportBackgroundColor: {
    backgroundColor: theme.colors.activityReportSubHeaderBg,
  },
})

type TFieldProps = {
  title: string
  children: React.ReactNode
  lang: string
}

const Field: React.FC<TFieldProps> = ({
  title,
  children,
  lang,
}): JSX.Element => {
  const directionalStyles = createDirectionalStyles(commonStyles, lang)

  return (
    <View style={directionalStyles.container}>
      <Text
        style={[commonStyles.fieldTitle, directionalStyles.text]}
      >{`${title}: `}</Text>
      <Text style={[commonStyles.fieldContent, directionalStyles.text]}>
        {children}
      </Text>
    </View>
  )
}

type CommonHeaderProps = {
  children: React.ReactNode
  customStyle: { paddingBottom: number } | object
  lang: string
}

const CommonHeader: React.FC<CommonHeaderProps> = ({
  children,
  customStyle = {},
  lang,
}): JSX.Element => {
  const directionalStyles = createDirectionalStyles(commonStyles, lang)

  return <View style={[directionalStyles.root, customStyle]}>{children}</View>
}

const renderField = (
  title: string,
  content: React.ReactNode,
  lang: string,
): JSX.Element => (
  <Field
    title={title}
    lang={lang}
  >
    {content}
  </Field>
)

export const AgentActivityHeader: React.FC<TAgentReportProps> = ({
  entity,
  assignedPassport,
}: TAgentReportProps): JSX.Element | null => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  // @ts-expect-error
  const agentDetails = assignedPassport[0]

  return (
    <CommonHeader
      customStyle={{
        backgroundColor:
          commonStyles.activityReportBackgroundColor.backgroundColor,
      }}
      lang={lang}
    >
      <View style={commonStyles.column}>
        {renderField(t('agent_activity_report.header.id'), entity.id, lang)}
        {renderField(
          t('agent_activity_report.header.creation_date'),
          typeof entity.checkIn === 'object' &&
            t('agent_activity_report.header.creation_date_format', {
              date: new Date(entity.checkIn.timestamp),
            }),
          lang,
        )}
        {renderField(
          t('agent_activity_report.header.organisation'),
          agentDetails.organization,
          lang,
        )}
      </View>
      <View style={commonStyles.column}>
        {renderField(
          t('agent_activity_report.header.first_name'),
          agentDetails.firstname,
          lang,
        )}
        {renderField(
          t('agent_activity_report.header.last_name'),
          agentDetails?.lastname,
          lang,
        )}
        {renderField(
          t('agent_activity_report.header.job'),
          agentDetails.job,
          lang,
        )}
      </View>
    </CommonHeader>
  )
}

export const AlarmHeader: React.FC<TAlarmReportProps> = ({
  entity,
  assignedPassport,
}: TAlarmReportProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  return (
    <CommonHeader
      customStyle={{
        backgroundColor: commonStyles.alarmBackgroundColor.backgroundColor,
      }}
      lang={lang}
    >
      <View style={commonStyles.column}>
        {renderField(t('alarm_report.header.id'), entity.id, lang)}
        {renderField(
          t('alarm_report.header.creation_date'),
          t('alarm_report.header.creation_date_format', {
            date: new Date(entity.creationDate),
          }),
          lang,
        )}
        {renderField(
          t('alarm_report.header.originator_system'),
          entity.originatorSystem,
          lang,
        )}
        {renderField(
          t('alarm_report.header.assigned_user'),
          assignedPassport?.username,
          lang,
        )}
      </View>
      <View style={commonStyles.column}>
        {renderField(t('alarm_report.header.status'), entity.status, lang)}
        {renderField(t('alarm_report.header.category'), entity.category, lang)}
        {renderField(t('alarm_report.header.severity'), entity.severity, lang)}
        {renderField(
          t('alarm_report.header.certainty'),
          entity.certainty,
          lang,
        )}
      </View>
    </CommonHeader>
  )
}
